import { createRouter, createWebHistory } from "vue-router";
import HomePage from "@/components/HomePage.vue";
import OrderView from "@/components/OrderView.vue";
import BartenderView from "@/components/BartenderView.vue";
import SparkControl from "@/components/SparkControl.vue";

// Route definitions
const routes = [
  {
    path: "/",
    name: "home",
    component: HomePage,
    props: (route) => ({ destination: route.query.d || "Default Destination" }),
  },
  {
    path: "/order",
    name: "order",
    component: OrderView,
    props: (route) => ({ destination: route.query.d || "Default Destination" }),
  },
  {
    path: "/bar",
    name: "BartenderView",
    component: BartenderView,
    beforeEnter: (to, from, next) => {
      const passcode = prompt("Enter passcode:");
      if (passcode === process.env.VUE_APP_BARTENDER_PASSWD) { 
        next();
      } else {
        alert("Incorrect passcode!");
        next(false);
      }
    },
  },
  {
    path: "/spark",
    name: "spark",
    component: SparkControl,
    props: (route) => ({ destination: route.query.d || "Default Destination" }),
  }
];

// Create and export the router
const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
