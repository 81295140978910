<template>
  <div class="home-container">
    <h2 class="welcome-heading">Welcome to Globant Playground!</h2>
    <div class="options-container">
      <div class="option">
        <img src="@/assets/globar.png" alt="Drinks" class="option-image"/>
        <button @click="navigate('order')" class="action-button">I'm Thirsty</button>
      </div>
      <div class="option">
        <img src="@/assets/spark.png" alt="Robot" class="option-image"/>
        <button @click="navigate('spark')" class="action-button">I'm Bored</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomePage",
  props: {
    destination: {
      type: String,
      required: true
    }
  },
  methods: {
    navigate(page) {
      this.$router.push({ 
        name: page, 
        query: { d: this.destination }
      });
    }
  }
};
</script>

<style scoped>
.home-container {
  text-align: center;
  padding: 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.welcome-heading {
  text-align: center;
  margin: 20px 0 40px 0;
  font-size: 3em;
  color: #333;
}

.options-container {
  display: flex;
  justify-content: center;
  gap: 100px;
  margin: 0 auto;
  max-width: 1400px;
  padding: 20px;
}

.option {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.option-image {
  width: 600px;
  height: 600px;
  object-fit: cover;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease;
}

.option-image:hover {
  transform: scale(1.02);
}

.action-button {
  padding: 20px 40px;
  font-size: 24px;
  border: none;
  border-radius: 8px;
  background-color: #C0D639;
  color: black;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 300px;
  font-weight: 600;
}

.action-button:hover {
  background-color: #a8bc33;
  transform: translateY(-2px);
}

/* Large Desktop styles */
@media (max-width: 1400px) {
  .option-image {
    width: 450px;
    height: 450px;
  }
}

/* Tablet styles */
@media (max-width: 1200px) {
  .option-image {
    width: 300px;
    height: 300px;
  }
  
  .action-button {
    min-width: 250px;
    font-size: 20px;
  }
  
  .welcome-heading {
    font-size: 2.5em;
  }

  .options-container {
    gap: 60px;
  }
}

/* Mobile styles */
@media (max-width: 768px) {
  .options-container {
    flex-direction: column;
    gap: 40px;
  }

  .option-image {
    width: 200px;
    height: 200px;
  }

  .action-button {
    min-width: 200px;
    font-size: 18px;
    padding: 15px 30px;
  }

  .welcome-heading {
    font-size: 2em;
    margin-bottom: 40px;
  }
}
</style> 