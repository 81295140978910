<template>
  <div class="spark-container">
    <div class="call-spark-container">
      <button class="call-spark-button" @click="callSpark" :disabled="isLoading">
        {{ isLoading ? 'Calling Spark...' : 'Come here Spark' }}
      </button>
    </div>
    <div class="embed-container">
      <iframe 
        src="https://embeds.inorbit.ai/embeds/rnLasGAxn5CP7bj32/iQXOd1bVz6vj3VNPLVsm?ctx=(robot:(robotId:884102897))&appKey=u5qV7fbmDtRnNPgt"        
        width="100%" 
        height="100%" 
        style="border:none;" 
        scrolling="no"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "SparkControl",
  props: {
    destination: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoading: false
    };
  },
  methods: {
    async callSpark() {
      this.isLoading = true;
      try {
        const response = await fetch('/api/spark/call', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            destination: this.destination
          })
        });
        const data = await response.json();
        if (!data.success) {
          throw new Error(data.message);
        }
      } catch (error) {
        console.error('Error calling Spark:', error);
        alert('Failed to call Spark. Please try again.');
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped>
.spark-container {
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #f5f5f5;
}

.call-spark-container {
  text-align: center;
  margin: 20px 0 30px 0;
  padding: 10px;
}

.call-spark-button {
  padding: 15px 30px;
  font-size: 20px;
  background-color: #C0D639;
  color: black;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 250px;
  font-weight: 600;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.call-spark-button:hover:not(:disabled) {
  background-color: #a8bc33;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.call-spark-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.embed-container {
  width: 100%;
  height: calc(100vh - 180px); /* Increased height */
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
}

/* Tablet styles */
@media (max-width: 1024px) {
  .call-spark-button {
    padding: 12px 24px;
    font-size: 18px;
    min-width: 220px;
  }

  .embed-container {
    height: calc(100vh - 160px);
  }
}

/* Mobile styles */
@media (max-width: 768px) {
  .spark-container {
    padding: 10px;
  }

  .call-spark-button {
    padding: 12px 24px;
    font-size: 18px;
    min-width: 200px;
  }

  .embed-container {
    height: calc(100vh - 140px);
  }
}
</style> 